import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ResourceSupport = () => (
  <Layout>
    <SEO title="Resource and Support" />
    <div className="container primary-bg">
      <div className="row">
        <div className="col">
          <h1 className="small-padding"> Resource and Support </h1>
          
          <Link className="full-cta" to="/intl-biz-nr">
            International Business & Non-Resident Support
          </Link>
          <Link className="full-cta" to="/resources-partners">
            Resources Partners
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default ResourceSupport
